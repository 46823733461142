// Generated by Framer (1249b65)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["dtqSmPCaP", "SZT6tg3ZL"];

const variantClassNames = {dtqSmPCaP: "framer-v-14xjjfc", SZT6tg3ZL: "framer-v-1ny6jto"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 72, delay: 0.2, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `perspective(1200px) ${t}`

const transition1 = {duration: 0, type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "dtqSmPCaP", "Variant 2": "SZT6tg3ZL"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "dtqSmPCaP"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "dtqSmPCaP", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1op8iny = activeVariantCallback(async (...args) => {
setVariant("SZT6tg3ZL")
})

useOnVariantChange(baseVariant, {default: onAppear1op8iny})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-L8p21", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 5360, intrinsicWidth: 8000, pixelHeight: 5360, pixelWidth: 8000, src: "https://framerusercontent.com/images/ETaq7VL19WU4w9AxqyGjyaxZQ.jpg?scale-down-to=2048"}} className={cx("framer-14xjjfc", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"dtqSmPCaP"} ref={ref} style={{...style}} {...addPropertyOverrides({SZT6tg3ZL: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-11hmr66"} data-framer-name={"Logo"} layoutDependency={layoutDependency} layoutId={"nAsjyuUKQ"} style={{opacity: 0}} variants={{SZT6tg3ZL: {opacity: 1}}}><Transition value={transition1}><RichText __fromCanvasComponent children={<React.Fragment><motion.h1 style={{"--framer-font-size": "300px", "--framer-letter-spacing": "-0.09em", "--framer-line-height": "0.83em", "--framer-text-alignment": "left"}}>francesc</motion.h1></React.Fragment>} className={"framer-16gsp3b"} layoutDependency={layoutDependency} layoutId={"ka24HiIbF"} style={{"--framer-paragraph-spacing": "0px"}} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/></Transition><Transition value={transition1}><RichText __fromCanvasComponent children={<React.Fragment><motion.h1 style={{"--framer-font-size": "300px", "--framer-letter-spacing": "-0.09em", "--framer-line-height": "0.83em", "--framer-text-alignment": "left"}}>enrich®</motion.h1></React.Fragment>} className={"framer-ugalyt"} layoutDependency={layoutDependency} layoutId={"eJmj5sWgx"} style={{"--framer-paragraph-spacing": "0px"}} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/></Transition></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-L8p21 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-L8p21 .framer-1uof4l2 { display: block; }", ".framer-L8p21 .framer-14xjjfc { height: 1116px; mix-blend-mode: color-dodge; overflow: hidden; position: relative; width: 1200px; }", ".framer-L8p21 .framer-11hmr66 { align-content: flex-start; align-items: flex-start; bottom: -252px; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; left: 20px; overflow: visible; padding: 0px 0px 0px 0px; position: absolute; width: min-content; z-index: 1; }", ".framer-L8p21 .framer-16gsp3b, .framer-L8p21 .framer-ugalyt { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-L8p21 .framer-11hmr66 { gap: 0px; } .framer-L8p21 .framer-11hmr66 > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-L8p21 .framer-11hmr66 > :first-child { margin-top: 0px; } .framer-L8p21 .framer-11hmr66 > :last-child { margin-bottom: 0px; } }", ".framer-L8p21.framer-v-1ny6jto .framer-11hmr66 { bottom: 20px; width: 85%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1116
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"SZT6tg3ZL":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FrameroPG5v4sPG: React.ComponentType<Props> = withCSS(Component, css, "framer-L8p21") as typeof Component;
export default FrameroPG5v4sPG;

FrameroPG5v4sPG.displayName = "INICIO";

FrameroPG5v4sPG.defaultProps = {height: 1116, width: 1200};

addPropertyControls(FrameroPG5v4sPG, {variant: {options: ["dtqSmPCaP", "SZT6tg3ZL"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FrameroPG5v4sPG, [])